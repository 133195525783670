
import {Component, Prop, Vue} from "vue-property-decorator";
import {HarcTuruEntity} from "@/entity/HarcTuruEntity";
import {HarcDonemiEntity} from "@/entity/HarcDonemiEntity";

@Component({
  components: {},
})
export default class HarcList extends Vue {
  @Prop({required: true}) takipId!: number;
  @Prop() hideCloseButton!: boolean | string;
  @Prop() items !: Array<HarcTuruEntity>;

  harcDonemleri !: Array<HarcDonemiEntity>;

  mounted(){
    this.items = this.items.filter(item =>(item.tutar) != 0);
  }

  get isHideClose() {
    return this.hideCloseButton || this.hideCloseButton === "";
  }

  search: string = "";
  headers = [
    {text: "Harç Türü", align: "start", sortable: false, value: "harc_turu",},
    {text: "Tarih", value: "tarih", sortable: false},
    {text: "Harç Oranı / Tutarı", value: "harc", sortable: false},
    {text: "Harç Tutarı", value: "harc_tutari", align: "right"},
  ];


  load() {
    this.$emit('load');
  }
}
