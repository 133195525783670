
import {Prop, Component, Mixins, Watch} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import TaksitSayisinaGoreTaksitlendirme
  from "@/components/anakart/taksitlendirme/taksitlendirme-tipleri/TaksitSayisinaGoreTaksitlendirme.vue";
import TaksitTutarinaGoreTaksitlendirme
  from "@/components/anakart/taksitlendirme/taksitlendirme-tipleri/TaksitTutarinaGoreTaksitlendirme.vue";
import OzelTaksitlendirme
  from "@/components/anakart/taksitlendirme/taksitlendirme-tipleri/OzelTaksitlendirme.vue";
import TaksitAnaBilgiler from "@/components/anakart/taksitlendirme/TaksitAnaBilgilerForm.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import TaksitlendirmeList from "@/components/lists/TaksitlendirmeList.vue";
import {TaksitHesapAyarEntity} from "@/entity/TaksitHesapAyarEntity";
import {TakipEntity} from "@/entity/TakipEntity";


@Component({
  components: {
    TaksitlendirmeList,
    FormWrapper,
    TaksitAnaBilgiler,
    OzelTaksitlendirme,
    TaksitTutarinaGoreTaksitlendirme,
    TaksitSayisinaGoreTaksitlendirme
  }
})
export default class TaksitlendirmeForm extends Mixins(ObjectInputMixin) {
  @Prop() genelToplam!: number;

  localValue!: TakipEntity;
  hesapDegerleri!: TaksitHesapAyarEntity;
  loading=false;
  tab = 0;

  @Watch('localValue.taksit_hesap_ayar')
  onTaksitHesapAyarChange() {
    if (typeof this.localValue.taksit_hesap_ayar == "string") {
      this.localValue.taksit_hesap_ayar = JSON.parse(this.localValue.taksit_hesap_ayar);
      if (this.localValue.taksit_hesap_ayar) {
        this.hesapDegerleri = this.localValue.taksit_hesap_ayar;
        if (this.localValue.taksit_hesap_ayar.taksit_turu == 'taksit_sayisina_gore') this.tab = 0;
        else if (this.localValue.taksit_hesap_ayar.taksit_turu == 'taksit_tutarina_gore') this.tab = 1;
        else if (this.localValue.taksit_hesap_ayar.taksit_turu == 'ozel_taksitlendirme') this.tab = 2;
      }
    }
    if(!this.localValue.taksit_hesap_ayar.taksitler || this.localValue.taksit_hesap_ayar.taksitler.length === 0){
      this.localValue.taksit_hesap_ayar.taksitler = this.localValue.taksitler;
    }
  }

  mounted() {
    if (this.localValue.taksit_hesap_ayar && typeof this.localValue.taksit_hesap_ayar == "string") {
      this.localValue.taksit_hesap_ayar = JSON.parse(this.localValue.taksit_hesap_ayar);
      this.hesapDegerleri = this.localValue.taksit_hesap_ayar;
      if (this.localValue.taksit_hesap_ayar.taksit_turu == 'taksit_sayisina_gore') this.tab = 0;
      else if (this.localValue.taksit_hesap_ayar.taksit_turu == 'taksit_tutarina_gore') this.tab = 1;
      else if (this.localValue.taksit_hesap_ayar.taksit_turu == 'ozel_taksitlendirme') this.tab = 2;
    } else {
      this.localValue.taksit_hesap_ayar = new TaksitHesapAyarEntity();
      this.input();
    }
  }

  load() {
    this.$emit('load');
  }

  sifirla() {
    this.localValue.taksitler = [];
    this.input();
  }

  hesapla() {
    this.loading = true;
    this.localValue.taksit_hesap_ayar.takip_id = this.localValue.id;
    if (this.tab == 0) {
      this.localValue.taksit_hesap_ayar.taksit_turu = 'taksit_sayisina_gore';
      this.localValue.taksit_hesap_ayar.taksit_tutari = null;
      if (!this.localValue.taksit_hesap_ayar.haftasonu_taksit_olmasin)
        this.localValue.taksit_hesap_ayar.haftasonu_taksit_olmasin = false;
      if (!this.localValue.taksit_hesap_ayar.taksit_tutari_yuvarlansin_mi)
        this.localValue.taksit_hesap_ayar.taksit_tutari_yuvarlansin_mi = false;
    } else if (this.tab == 1) {
      this.localValue.taksit_hesap_ayar.taksit_turu = 'taksit_tutarina_gore';
      this.localValue.taksit_hesap_ayar.taksit_periyodu = "ay";
      this.localValue.taksit_hesap_ayar.taksit_periyod_sayisi = 1;
      this.localValue.taksit_hesap_ayar.taksit_sayisi = null;
      this.localValue.taksit_hesap_ayar.taksit_tutari_yuvarlansin_mi = false;
    } else if (this.tab == 2) {
      this.localValue.taksit_hesap_ayar.taksit_turu = 'ozel_taksitlendirme';
      this.localValue.taksit_hesap_ayar.taksit_sayisi = null;
      this.localValue.taksit_hesap_ayar.taksit_tutari_yuvarlansin_mi = false;
    }
      this.input();
      this.$http.post('/api/v1/taksit/hesapla', this.localValue.taksit_hesap_ayar).then(response => {
        this.localValue.taksit_hesap_ayar = response.data;
        this.localValue.taksitler=response.data.taksitler
        this.input();
      }).finally(() => this.loading = false);
    }

  updatedHesap(data: { index: number | Array<number>, type: string }) {
    if(!this.localValue.taksitler){
      this.sifirla();
    }
    this.$http.put('/api/v1/taksit/hesapla', {...this.localValue.taksit_hesap_ayar, ...data}).then(response => {
      this.localValue.taksit_hesap_ayar = response.data;
      this.localValue.taksitler=response.data.taksitler;
      this.input();
    });
  }

  save() {
    if(!this.localValue.taksitler){
      this.sifirla();
    }
    this.$http.post('/api/v1/takip/' + this.$route.params.id + '/taksit', this.localValue.taksit_hesap_ayar).then((response) => {
      this.$emit("onSuccess");
    });
  }
}
