
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {TakipEntity} from "@/entity/TakipEntity";
import {BankaHesabiSahibiLabel} from "@/enum/BankaHesabiSahibi";

import TakipTalebiGenerator from "@/classes/evrak/TakipTalebi/TakipTalebiGenerator";
import FormWrapper from "@/components/FormWrapper.vue";
import Evrak from "@/classes/evrak/Evrak";
import {AlacakKalemiAlani} from "@/enum/AlacakKalemiAlani";

@Component({
  components: {FormWrapper},
})
export default class TakipTalebiTaslak extends Vue {
  @Prop() takip!: TakipEntity;
  @Prop() isKartNoVisible!: boolean;

  ttEvrak: Evrak = new Evrak();
  bankaHesabiSahibiLabel = BankaHesabiSahibiLabel;
  alacakKalemiAlani = AlacakKalemiAlani;

  mounted() {
    if (this.takip) {
      this.onTakipChange();
    }
    // if(this.ttEvrak.TakipYoluData[0] === "İhtiyati Haciz - Haciz"){
    //   this.ttEvrak.IlamData[0] += "<span> ihtiyati haciz kararı</span>";
    // } else {
    //   this.ttEvrak.IlamData[0] += "<span>ilamı</span>";
    // }

  }

  @Watch("takip")
  onTakipChange() {
    let takipTalebiGenerator = new TakipTalebiGenerator()
    takipTalebiGenerator.setTakip(this.takip);
    this.ttEvrak = takipTalebiGenerator.getTakipTalebiEvrak();
  }

  print() {
    this.$helper.printComponent('takipTalebi');
  }
}
