
import {Component, Mixins, Prop} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import Dates from "@/components/inputs/Dates.vue";
import {TaksitHesapAyarEntity} from "@/entity/TaksitHesapAyarEntity";

@Component({
  components: {Dates}
})
export default class TaksitSayisinaGoreTaksitlendirme extends Mixins(ObjectInputMixin) {
  @Prop({default: false}) disabledHesaplamaButton!: boolean;

  localValue!: TaksitHesapAyarEntity;
  isError: boolean = false;
  hataMesaji = "Hesaplamada hata oluştu.";
  periyodlar = [
    {text: "Ay", value: "ay"},
    {text: "Hafta", value: "hafta"}
  ]

  mounted() {
    this.localValue.taksit_periyodu = this.periyodlar[0].value;
    this.localValue.taksit_periyod_sayisi = 1;
  }

  load() {
    if (!this.localValue.taksit_baslangic_tarihi) {
      this.isError = true;
      this.hataMesaji = "Başlangıç tarihini giriniz.";
    } else if (!this.localValue.taksit_periyodu || !this.localValue.taksit_periyod_sayisi) {
      this.isError = true;
      this.hataMesaji = "Periyot bilgilerini giriniz.";
    } else if (!this.localValue.taksit_sayisi) {
      this.isError = true;
      this.hataMesaji = "Taksit sayısını giriniz.";
    } else {
      this.isError = false;
      if (!this.localValue.haftasonu_taksit_olmasin)
        this.localValue.haftasonu_taksit_olmasin = false;
      if (!this.localValue.taksit_tutari_yuvarlansin_mi)
        this.localValue.taksit_tutari_yuvarlansin_mi = false;
      this.$emit('success');
    }
  }
}
