export enum UyapDigerEvrakTuru {
    aciz_belgesi_hazirlanmasi = 32,
    arac_uzerindeki_haciz_serhinin_kaldirilmasi = 17,
    arac_uzerindeki_yakalama_serhinin_kaldirilmasi = 16,
    bankadan_hacizli_paranin_istenilmesi = 42,
    borclu_tarafindan_yapilan_odemelerin_hesaba_aktarilmasi = 14,
    dosya_hesabinin_yapilmasi_ve_dosyaya_kaydedilmesi = 13,
    dosyada_hacizli_malin_satisi = 27,
    dosyadaki_avansin_iadesi = 37,
    dosyadaki_hacizlerin_kaldirilmasi = 21,
    dosyadaki_IBAN_bilgisinin_guncellenmesi = 15,
    dosyanin_islemden_kaldirilmasi = 26,
    dosyanin_yetkili_icra_dairesine_gonderilmesi = 25,
    hacizli_malin_kiymet_takdiri_icin_talimat_yazilmasi = 30,
    hacizli_malin_satisi_icin_talimat_yazilmasi = 29,
    hacizli_tasinir_mallarin_muhafaza_altina_alinmasi = 20,
    haricen_tahsilat_bildirimi = 39,
    iIK121_maddeye_gore_yetki_verilmesi = 34,
    iIK150C_serhi_eklenmesi = 33,
    kiymet_takdirinin_yapilmasi = 28,
    maas_uzerindeki_hacizlerin_kaldirilmasi = 18,
    muhafaza_altindaki_mallarin_haciz_baki_kalarak_yeddiemin_degisikligi = 22,
    rehin_acigi_belgesi_hazirlanmasi = 35,
    rehinin_paraya_cevrilmesi_serhi = 46,
    takibin_kesinlesmesi = 31,
    tasinmaz_haczinin_kaldirilmasi = 19,
    yuzuncu_maddeye_yarar_bilgi_istenilmesi = 36
}