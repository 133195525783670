import { render, staticRenderFns } from "./TaksitSayisinaGoreTaksitlendirme.vue?vue&type=template&id=07c8fe84&scoped=true"
import script from "./TaksitSayisinaGoreTaksitlendirme.vue?vue&type=script&lang=ts"
export * from "./TaksitSayisinaGoreTaksitlendirme.vue?vue&type=script&lang=ts"
import style0 from "./TaksitSayisinaGoreTaksitlendirme.vue?vue&type=style&index=0&id=07c8fe84&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07c8fe84",
  null
  
)

export default component.exports