import {TaksitEntity} from "@/entity/TaksitEntity";

export class TaksitHesapAyarEntity {
    takip_id!: number;
    taksit_baslangic_tarihi!: Date;
    taksit_periyod_sayisi!: number;
    taksit_periyodu!: string;
    taksit_sayisi!: number|null;
    taksit_turu!: string;
    faiz_var_mi:boolean = false;
    taksit_tutari!: number | null;
    haftasonu_taksit_olmasin: boolean = false;
    taksit_tutari_yuvarlansin_mi: boolean = false;
    taksitler : Array<TaksitEntity> = [];
    ana_para!: number;
    isleyen_faiz!: number;
    toplam_borc!: number;
    hesaplansin_mi!:boolean;
}