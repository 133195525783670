
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import TahsilatForm from "@/components/forms/TahsilatForm.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import {TahsilatEntity} from "@/entity/TahsilatEntity";
import TaksitlendirmeForm from "@/components/anakart/taksitlendirme/TaksitlendirmeForm.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import {TakipEntity} from "@/entity/TakipEntity";
import {TakipTalebiEntity} from "@/entity/TakipTalebiEntity";
import {AlacakEntity} from "@/entity/AlacakBelgeler/AlacakEntity";
import AlacakKalemiEntity from "@/entity/AlacakBelgeler/AlacakKalemiEntity";
import {ParaBirimi} from "@/enum/ParaBirimi";
import FormWrapper from "@/components/FormWrapper.vue";
import TahsilatMakbuzuTaslak from "@/components/TahsilatMakbuzuTaslak.vue";
import {TahsilatTuruLabel} from "@/enum/TahsilatTuru";

@Component({
  components: {TahsilatMakbuzuTaslak, FormWrapper, FormDialog, TaksitlendirmeForm, DeleteDialog, TahsilatForm}
})
export default class TahsilatList extends Vue {
  @Prop() takip!: TakipEntity;
  @Prop() genelToplam!: number;

  tahsilatTuruLabel = TahsilatTuruLabel
  paraBirimi = ParaBirimi;
  expanded: number = 0;
  headers = [
    {text: "Sıra No", align: "start", sortable: true, value: "index", width: 30},
    {text: "Borçlu", align: "start", sortable: false, value: "borclu"},
    {text: "Tahsilat Türü", sortable: false, value: "tahsilat_turu"},
    {text: "Tahsilat Tarihi", value: "tarih"},
    {text: "Yatırılan Tutar", value: "tutar", width: 125},
    {text: "Tahsil Harcı", value: "tahsil_harci", width: 125},
    {text: "Net Tutar", align: "right", value: "net_tutar", width: 125},
    {text: "İşlemler", align: "center", value: "actions", sortable: false, width: 150}
  ];
  tahsilatTutarlari: TahsilatTutarlari = new TahsilatTutarlari();

  get defaultTahsilat(){
    let tahsilatObj = new TahsilatEntity();
    return tahsilatObj;
  }

  mounted() {
    this.calculate();
  }

  @Watch("takip.tahsilatlar", {deep: true})
  onItemsChange() {
    this.calculate();
  }

  load() {
    this.$emit('load');
  }


  calculate() {
    this.tahsilatTutarlari = new TahsilatTutarlari();

    this.takip.tahsilatlar.forEach((tahsilat: TahsilatEntity) => {
      if (tahsilat.tarih && tahsilat.tutar) {
        let tutar = tahsilat.para_birimi_id === ParaBirimi.TRY ? Number(tahsilat.tutar) : Number(tahsilat.tutar) * Number(tahsilat.kur);
        let cezaeviHarci = Number(tahsilat.para_birimi_id == ParaBirimi.TRY ? tahsilat.cezaevi_harci : tahsilat.cezaevi_harci * tahsilat.kur);
        let tahsilHarci = Number(tahsilat.para_birimi_id == ParaBirimi.TRY ? tahsilat.tahsil_harci : tahsilat.tahsil_harci * tahsilat.kur);

        if (!this.takip.takip_tarihi || tahsilat.tarih < this.takip.takip_tarihi) {
          this.tahsilatTutarlari.takipOncesiTahsilat += tutar;
        } else {
          this.tahsilatTutarlari.takipSonrasiTahsilat += tutar;
        }

        this.tahsilatTutarlari.cezaeviHarci += cezaeviHarci;
        this.tahsilatTutarlari.tahsilHarci += tahsilHarci;
      }
    });
    this.tahsilatTutarlari.takipToplami = this.calculatetakipToplami();
  }

  calculatetakipToplami(): number {
    let tutar = 0;
    this.takip.talepler.forEach((talep: TakipTalebiEntity) => {
      talep.alacaklar.forEach((alacak: AlacakEntity) => {
        alacak.alacak_kalemleri.forEach((alacakKalemi: AlacakKalemiEntity) => {
          if (!this.takip.takip_tarihi || alacakKalemi.vade_tarihi <= this.takip.takip_tarihi)
            tutar += Number(alacakKalemi.para_birimi_id == ParaBirimi.TRY ? alacakKalemi.tutar : alacakKalemi.tutar * alacakKalemi.kur);
        });
      });
    });
    return tutar;
  }
}

class TahsilatTutarlari {
  takipToplami: number = 0;
  takipOncesiTahsilat: number = 0;
  takipSonrasiTahsilat: number = 0;
  taahhut: number = 0;
  tahsilHarci: number = 0;
  cezaeviHarci: number = 0;

  get toplamTahsilat() {
    return Number(this.takipOncesiTahsilat) + Number(this.takipSonrasiTahsilat);
  }

  get toplamHarc() {
    return Number(this.tahsilHarci) + Number(this.cezaeviHarci);
  }

  get toplamNetTahsilat(): number {
    return this.toplamTahsilat - this.toplamHarc;
  }

  get kalanTahsilHarciTutari(): number {
    return this.takipToplami * 0.0455 - this.tahsilHarci;
  }

}
