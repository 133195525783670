
import {Prop, Component, Vue} from "vue-property-decorator";
import {TakipTalebiEntity} from "@/entity/TakipTalebiEntity";
import {AlacakEntity} from "@/entity/AlacakBelgeler/AlacakEntity";
import AlacakKalemiEntity from "@/entity/AlacakBelgeler/AlacakKalemiEntity";
import {TakipEntity} from "@/entity/TakipEntity";
import {AlacakKalemiAlani} from "@/enum/AlacakKalemiAlani";

@Component({
  components: {}
})
export default class TaksitAnaBilgiler extends Vue {
  @Prop() takip!: TakipEntity;
  @Prop() genelToplam!: number;

  alacakKalemiAlani = AlacakKalemiAlani;

  get tahsilHarci() {
    let harcToplami = 0;
    if (this.takip) {
      this.takip.talepler.forEach((takipTalebi: TakipTalebiEntity) => {
        takipTalebi.alacaklar.forEach((alacak: AlacakEntity) => {
          alacak.alacak_kalemleri.forEach((alacakKalemi: AlacakKalemiEntity) => {
            if (alacakKalemi.alacak_kalemi_alani.id == this.alacakKalemiAlani.harc && alacakKalemi.harc_turu_id == 1) {
              //harc_turu_id == 1 tahsil harcı
              harcToplami += Number(alacakKalemi.tahsil_edilmeyen_tutar);
            }
          });
        });
      });
    }
    return harcToplami;
  }
}
