
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import Dates from "@/components/inputs/Dates.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import {TaksitHesapAyarEntity} from "@/entity/TaksitHesapAyarEntity";

@Component({
  components: {Tutar, Dates}
})
export default class TaksitTutarinaGoreTaksitlendirme extends Mixins(ObjectInputMixin) {
  @Prop() esasBorc!: number;
  @Prop({default: false}) disabledHesaplamaButton!: boolean;

  localValue!: TaksitHesapAyarEntity;
  isError: boolean = false;
  hataMesaji = "Hesaplamada hata oluştu.";

  load() {
    if (!this.localValue.taksit_baslangic_tarihi) {
      this.isError = true;
      this.hataMesaji = "Başlangıç tarihini giriniz.";
    } else if (!this.localValue.taksit_tutari) {
      this.isError = true;
      this.hataMesaji = "Taksit tutarını giriniz.";
    } else if(this.localValue.taksit_tutari && this.esasBorc < this.localValue.taksit_tutari){
      this.isError = true;
      this.hataMesaji = "Taksit tutatı toplam borçtan büyük olamaz.";
    }else {
      this.isError = false;
      this.$emit('success');
    }
  }
}
