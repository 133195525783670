import {TakipEntity} from "@/entity/TakipEntity";
import KapakHesabi from "@/classes/evrak/KapakHesabi/KapakHesabi";
import {TakipTalebiEntity} from "@/entity/TakipTalebiEntity";
import {AlacakEntity} from "@/entity/AlacakBelgeler/AlacakEntity";
import AlacakKalemiEntity from "@/entity/AlacakBelgeler/AlacakKalemiEntity";
import {ParaBirimi} from "@/enum/ParaBirimi";
import {TahsilatEntity} from "@/entity/TahsilatEntity";
import {ParaBirimiEntity} from "@/entity/ParaBirimiEntity";
import {AlacakKalemiAlani} from "@/enum/AlacakKalemiAlani";
import {BorcluEntity} from "@/entity/BorcluEntity";
import {NotEntity} from "@/entity/NotEntity";
import {TaksitEntity} from "@/entity/TaksitEntity";

export default class KapakHesabiCalculator {
    takip!: TakipEntity;
    isDovizTakibi: boolean = true; // TODO: Bu data takipten alınacak!! @Zulfigaroglu
    tutarTl: number = 0;

    paraBirim: ParaBirimiEntity = {
        "id": 1,
        "isim": "Türk Lirası",
        "kisa_isim": "TL",
        "simge": "",
        "yerel_kod": "TR",
        "para_birim_kod": 'TRY',
        "currencyCode": 'tr-TR',
    };

    setTakip(takip: TakipEntity) {
        this.takip = takip;
    }

    calculateKapakHesabi(): KapakHesabi {
        let kapakHesabi = new KapakHesabi();

        this.takip.tahsilatlar.forEach((tahsilat: TahsilatEntity) => {

            kapakHesabi.setTahsilatParaBirimleri(tahsilat.para_birimi);

            if (tahsilat.tarih < this.takip.takip_tarihi) {
                if (Number(tahsilat.tutar) > 0) {
                    let takipOncesiTahsilatToplami = Number(tahsilat.net_tutar) * Number(tahsilat.kur);
                    if (tahsilat.para_birimi.id === ParaBirimi.TRY || this.isDovizTakibi) {
                        //console.log("giriyor takip oncesi tahsilata")
                        kapakHesabi.setTakipOncesiTahsilatData(tahsilat.para_birimi, tahsilat.net_tutar);
                    } else {
                        kapakHesabi.setTakipOncesiTahsilatData(tahsilat.para_birimi, takipOncesiTahsilatToplami);
                    }
                }
            } else {
                if (Number(tahsilat.tutar) > 0) {
                    let takipSonrasiTahsilatToplami = Number(tahsilat.net_tutar) * Number(tahsilat.kur);
                    if (tahsilat.para_birimi.id === ParaBirimi.TRY || this.isDovizTakibi) {
                        kapakHesabi.setTakipSonrasiTahsilatData(tahsilat.para_birimi, tahsilat.tutar);
                    } else {
                        kapakHesabi.setTakipSonrasiTahsilatData(tahsilat.para_birimi, takipSonrasiTahsilatToplami);
                    }
                }
            }
        });


        if(this.takip.notlar){
            kapakHesabi.setNotData(this.takip.notlar);
        }

        if(this.takip.taksitler){
            let faizlerToplami=0
            this.takip.taksitler.forEach((taksit: TaksitEntity) => {
                faizlerToplami+=Number(taksit.isleyen_faiz);
            })
            kapakHesabi.getOdemeTaahhuduToplamFaiz(faizlerToplami);
            kapakHesabi.getOdemeTaahhuduEsasBorc(faizlerToplami);
        }

        if(this.takip.borclular){
            //HACİZLERİ İCTK İÇERİSİNDE GÖSTERMEK İÇİN EKLENDİ
            kapakHesabi.getHacizLer(this.takip.borclular);
        }
        if(this.takip.tahsilatlar){
            //TAHSİLATLARI İCTK İÇERİSİNDE GÖSTERMEK İÇİN EKLENDİ
            kapakHesabi.getTahsilatlar(this.takip.tahsilatlar);
        }


        this.takip.talepler.forEach((takipTalebi: TakipTalebiEntity) => {
            takipTalebi.alacaklar.forEach((alacak: AlacakEntity) => {
                alacak.alacak_kalemleri.forEach((alacakKalemi: AlacakKalemiEntity) => {


                    if(alacakKalemi.harc_turu_id>0){
                        kapakHesabi.getHarclar(alacakKalemi);
                    }

                    if(alacakKalemi.masraf_cesidi_id>0){
                        kapakHesabi.getMasraf(alacakKalemi);
                    }

                    //TOPLAM TUTARI BULMAK İÇİN KONTROL YAZILDI
                    if (Number(alacakKalemi.tutar) > 0) {

                        if (alacakKalemi.harc_turu_id != 12) {
                            if(alacakKalemi.para_birimi_id!=1){
                                if(this.takip.takip_tarihli_kur){
                                    // @ts-ignore
                                    this.tutarTl = Number(alacakKalemi.tahsil_edilmeyen_tutar) * (this.takip.takip_tarihli_kur[0] ? this.takip.takip_tarihli_kur[0].tutar : 1);
                                }
                            }else{
                                this.tutarTl = Number(alacakKalemi.tahsil_edilmeyen_tutar);

                            }
                            kapakHesabi.toplamTLTutarData += Number(this.tutarTl);

                            if (alacakKalemi.para_birimi_id == 1) {
                                this.paraBirim = alacakKalemi.para_birimi;
                            }
                        }
                        //TAKİP ONCESİ KALEMLERİ AYRI BİR MAP ARRAYA PUSHLAMAK İÇİN KONTROL YAZILDI
                        if (alacakKalemi.alacak_kalemi_alani.takip_oncesi_mi) { //normalde 1 ve 0 donduruyo


                            if(alacakKalemi.alacak_kalemi_alani_id==2){
                                kapakHesabi.getOdemeTaahhuduFaiz1(alacakKalemi)
                            }

                            kapakHesabi.setHarcaEsasDeger(alacakKalemi.para_birimi,alacakKalemi,this.takip.takip_tarihli_kur);

                            if (this.takip.tl_takibi) {
                                kapakHesabi.setAlacakKalemiData(alacakKalemi.alacak_kalemi_alani_id, this.paraBirim, this.tutarTl, alacakKalemi.takip_oncesi_tahsil_edilmeyen_tutar * alacakKalemi.kur);
                                kapakHesabi.setNewNetKalanData(alacakKalemi.para_birimi.id,alacakKalemi.takip_sonrasi_tahsil_edilmeyen_tutar);
                            } else {
                                if (alacakKalemi.para_birimi.id === ParaBirimi.TRY) {
                                    kapakHesabi.setAlacakKalemiData(alacakKalemi.alacak_kalemi_alani_id, alacakKalemi.para_birimi, alacakKalemi.tutar, alacakKalemi.takip_oncesi_tahsil_edilmeyen_tutar);
                                    kapakHesabi.setNewNetKalanData(alacakKalemi.para_birimi.id,alacakKalemi.takip_sonrasi_tahsil_edilmeyen_tutar);
                                } else {
                                    kapakHesabi.setAlacakKalemiData(alacakKalemi.alacak_kalemi_alani_id, alacakKalemi.para_birimi, alacakKalemi.tutar, alacakKalemi.takip_oncesi_tahsil_edilmeyen_tutar);
                                    kapakHesabi.setNewNetKalanData(alacakKalemi.para_birimi.id,alacakKalemi.takip_sonrasi_tahsil_edilmeyen_tutar);
                                }
                            }
                        }

                        //TAKİP SONRASI KALEMLERİ AYRI BİR MAP ARRAYA PUSHLAMAK İÇİN KONTROL YAZILDI
                        if (!alacakKalemi.alacak_kalemi_alani.takip_oncesi_mi) {

                            if(alacakKalemi.alacak_kalemi_alani_id==AlacakKalemiAlani.takipSonrasiFaiz){
                                kapakHesabi.getOdemeTaahhuduFaiz2(alacakKalemi)
                            }
                            //İCRA TAKİP KARTI TAKİP SONRASI KALEMLER
                            kapakHesabi.setTakipSonrasiIcraTakipKartiData(alacakKalemi);
                            if (alacakKalemi.harc_turu_id != 12 ){ // peşin harcın hesabın toplamına dahil olamaması gerektiği için eklendi bu koşul eklendi
                                if (this.takip.tl_takibi) {
                                    kapakHesabi.setTakipSonrasiAlacakKalemiData(alacakKalemi.alacak_kalemi_alani_id, this.paraBirim, this.tutarTl, alacakKalemi.takip_sonrasi_tahsil_edilmeyen_tutar * alacakKalemi.kur);
                                } else {
                                    if (alacakKalemi.para_birimi.id === ParaBirimi.TRY || this.isDovizTakibi) {
                                        kapakHesabi.setTakipSonrasiAlacakKalemiData(alacakKalemi.alacak_kalemi_alani_id, alacakKalemi.para_birimi, alacakKalemi.tutar, alacakKalemi.takip_sonrasi_tahsil_edilmeyen_tutar);
                                    } else {
                                        kapakHesabi.setTakipSonrasiAlacakKalemiData(alacakKalemi.alacak_kalemi_alani_id, alacakKalemi.para_birimi, this.tutarTl, alacakKalemi.takip_sonrasi_tahsil_edilmeyen_tutar);
                                    }
                                }
                            }
                        }
                    }
                });
            });
        });
        return kapakHesabi;
    }

}