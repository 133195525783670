
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import Dates from "@/components/inputs/Dates.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import {TaksitHesapAyarEntity} from "@/entity/TaksitHesapAyarEntity";

@Component({
  components: {Tutar, Dates}
})
export default class OzelTaksitlendirme extends Mixins(ObjectInputMixin) {
  @Prop() esasBorc!: number;
  @Prop({default: false}) disabledHesaplamaButton!: boolean;

  localValue!: TaksitHesapAyarEntity;
  isError: boolean = false;
  hataMesaji = "Hesaplamada hata oluştu.";
  periyodlar = [
    {text: "Ay", value: "ay"},
    {text: "Hafta", value: "hafta"}
  ]

  mounted(){
    this.localValue.hesaplansin_mi = false;
  }

  faizVarMiChange(){
    if(this.localValue.faiz_var_mi) {
      this.localValue.hesaplansin_mi=true;
    } else
      this.localValue.hesaplansin_mi=false;
    this.input()
  }

  inputKontrol() {
    if (!this.localValue.taksit_baslangic_tarihi) {
      this.isError = true;
      this.hataMesaji = "Başlangıç tarihini giriniz.";
      return false;
    }
    if (!this.localValue.taksit_tutari) {
      this.isError = true;
      this.hataMesaji = "Taksit tutarını giriniz.";
      return false;
    }
    if (this.localValue.taksit_tutari && this.esasBorc < this.localValue.taksit_tutari) {
      this.isError = true;
      this.hataMesaji = "Taksit tutarı toplam borçtan büyük olamaz.";
      return false;
    }
    this.isError = false;
    return true;
  }

  load() {
    if (this.inputKontrol()) {
      this.$emit('success');
    }
  }

  taksitEkle() {
    if (this.inputKontrol()) {
      this.localValue.hesaplansin_mi = false;
      this.input();
      this.$emit('success');
    }
  }

}
