

import {Component, Prop, Vue} from "vue-property-decorator";
import {TakipEntity} from "@/entity/TakipEntity";
import KartOzelindeHesapAyarForm from "@/components/forms/KartOzelindeHesapAyarForm.vue";
import HesapTarihiForm from "@/components/forms/HesapTarihiForm.vue";
import KapakHesabiInfo from "@/components/infos/KapakHesabiInfo.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import TakipKurForm from "@/components/forms/TakipKurForm.vue";
import {ParaBirimi} from "@/enum/ParaBirimi";
import {TakipDurumu} from "@/enum/TakipDurumu";
import HesapTablosuForm from "@/components/forms/HesapTablosuForm.vue";

@Component({
  components: {HesapTablosuForm, TakipKurForm, FormDialog, KapakHesabiInfo, HesapTarihiForm, KartOzelindeHesapAyarForm}
})
export default class KapakHesabi extends Vue {
  @Prop() faizData!: { hesaplanabilir: boolean, data: TakipEntity | { message: string } };
  @Prop() takip!: TakipEntity;
  @Prop() disabled!: string | boolean;
  @Prop() loading!: boolean;

  @Prop()hesapTarihi!:Date
  items = [{tab: 'Kapak Hesabı', content: 'Tab 1 Content'}];
  takipDurumu = TakipDurumu;



  get isDisabled() {
    return this.disabled || this.disabled === "";
  }

  get tTKColor() {
    return this.takip.takip_tarihli_kur[0]?.tarih == this.takip.takip_tarihi ? 'white' : 'red';
  }

  get showTKButton() {
    let value = false;
    this.takip.talepler.forEach((talep) => {
      talep.alacaklar.forEach(alacak => {
        if (alacak.para_birimi_id && alacak.para_birimi_id != ParaBirimi.TRY)
          value = true;
        alacak.asil_alacaklar.forEach(alacakKalemi => {
          if (alacakKalemi.para_birimi_id && alacakKalemi.para_birimi_id != ParaBirimi.TRY)
            value = true
        })
      })
    })
    return value;
  }

  load() {
    this.$emit('load')
  }
}
