
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import FormWrapper from "@/components/FormWrapper.vue";
import Evrak from "@/classes/evrak/Evrak";
import {TakipEntity} from "@/entity/TakipEntity";
import TakipTalebiGenerator from "@/classes/evrak/TakipTalebi/TakipTalebiGenerator";
import KapakHesabi from "@/classes/evrak/KapakHesabi/KapakHesabi";
import KapakHesabiCalculator from "@/classes/evrak/KapakHesabi/KapakHesabiCalculator";
import {AlacakKalemiAlani} from "@/enum/AlacakKalemiAlani";
import HesapTablosuInfo from "@/components/infos/HesapTablosuInfo.vue";


@Component({
  components: {HesapTablosuInfo, FormWrapper}
})
export default class TakipHarcYokTaslak extends Vue{
  @Prop() takip!: TakipEntity;
  @Prop() hesapTarihi!:Date

  AlacakKalemiAlani = AlacakKalemiAlani;
  kapakHesabi: KapakHesabi = new KapakHesabi();
  ttEvrak: Evrak = new Evrak();

  mounted() {
    if (this.takip) {
      this.onTakipChange();
      this.calculateKapakHesabi()
    }
  }
get toplamFaizTutar(){
  let toplam = 0;
  this.kapakHesabi.duzenlenmisTakipSonrasiKalemler.forEach(kalemler => {
    if (kalemler.id === this.AlacakKalemiAlani.takipSonrasiFaiz) {
      toplam += parseFloat(kalemler.tutar);
    }
  });
  return toplam.toFixed(2);
}

  @Watch("takip")
  onTakipChange() {
    let takipTalebiGenerator = new TakipTalebiGenerator()
    takipTalebiGenerator.setTakip(this.takip);
    this.ttEvrak = takipTalebiGenerator.getTakipTalebiEvrak();
  }
  calculateKapakHesabi() {
    if (this.takip.id) {
      let kapakHesabiCalculator: KapakHesabiCalculator = new KapakHesabiCalculator();
      kapakHesabiCalculator.setTakip(this.takip);
      this.kapakHesabi = kapakHesabiCalculator.calculateKapakHesabi();
      this.kapakHesabi.alacakKalemiAlanlari.sort(function (a, b) {
        return a - b;
      });
      this.kapakHesabi.takipSonrasialacakKalemiAlanlari.sort(function (a, b) {
        return a - b;
      });
    }
  }

  print() {
    this.$helper.printComponent('takipHarcYokTaslak');
  }
}
