import {ParaBirimiEntity} from "@/entity/ParaBirimiEntity";
import {TahsilatTuru} from "@/enum/TahsilatTuru";
import {BorcluEntity} from "@/entity/BorcluEntity";
import {AltTahsilatTuru} from "@/enum/AltTahsilatTuru";

export class TahsilatEntity {
    id!: number;
    alt_tahsilat_turu_id!: AltTahsilatTuru;
    borclu!: BorcluEntity;
    borclu_id!: number;
    cezaevi_harci!: number;
    ekleyen_ad_soyad!: string;
    ekleyen_id!: number;
    giris_tarihi: Date=new Date();
    kasaya_ajandaya_aktar!: boolean;
    kur!: number;
    mahsuplasan_tutar!: string;
    net_tutar: number = 0;
    manuel_alt_tur!: string;
    para_birimi!: ParaBirimiEntity;
    para_birimi_id!: number;
    tahsil_harci!: number;
    tahsil_harci_belge_no!: string;
    tahsil_harci_orani!: number;
    tahsilat_turu!: TahsilatTuru;
    tahsilati_yapan!: string;
    takip_id!: number;
    tarih!: Date;
    tutar!: number;
    vekalet_ucreti_belge_no!: string;
}