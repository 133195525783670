
import {Component, Prop, Vue} from "vue-property-decorator";
import FormWrapper from "@/components/FormWrapper.vue";
import {TahsilatEntity} from "@/entity/TahsilatEntity";
import {ParaBirimi} from "@/enum/ParaBirimi";
import {TakipEntity} from "@/entity/TakipEntity";
import {AlacakliEntity} from "@/entity/AlacakliEntity";
import {PersonelTuru} from "@/enum/PersonelTuru";
import {AdresTuru} from "@/enum/AdresTuru";

@Component({
  components: {FormWrapper}
})
export default class TahsilatMakbuzuTaslak extends Vue {
  @Prop() tahsilat!: TahsilatEntity;
  @Prop() takip!: TakipEntity;

  get alacaklilar() {
    let temp = "";
    this.takip.alacaklilar.forEach((alacakli: AlacakliEntity) => {
      temp += this.$helper.kisiKurumAd(alacakli.muvekkil) + ", ";
    })
    temp = temp.slice(0, -2);
    return temp;
  }

  get yetkiliAvukat() {
    this.takip.alacaklilar[0]?.muvekkil.vekalet.avukatlar.forEach((avukat: any) => {
      if (avukat.roles[0] == PersonelTuru.kurucu_avukat || avukat.roles[0] == PersonelTuru.idari_yonetici) {
        return avukat;
      }
    });
    return this.takip.alacaklilar[0]?.muvekkil.vekalet.avukatlar[0] ?? null;
  }

  makbuz = (this.yetkiliAvukat? this.yetkiliAvukat?.burolar[0].isim : '' )+ " \n" +
      (this.yetkiliAvukat?.burolar[0].adres.adres_tur_id==AdresTuru.mernis?'Mernis Adresi':(this.yetkiliAvukat?.burolar[0].adres.adres??'')) + " \n\n\n" +
      "TAHSİLAT MAKBUZU \n" +
      (this.takip.icra_dairesi?.isim ?? "") + "'nin, alacaklısı " + this.alacaklilar + " olan " +
      (this.takip.dosya_esas_no ?? "") + " sayılı dosyasına mahsuben, borçlu " + this.$helper.kisiKurumAd(this.tahsilat.borclu) +
      "'dan, yalnız " + this.$helper.tutarStr(this.tahsilat.tutar) + " " + ParaBirimi[this.tahsilat.para_birimi_id] +
      " (" + this.$helper.tutarToText(this.tahsilat.tutar) + " " + ParaBirimi[this.tahsilat.para_birimi_id] +
      ") tahsil edilmiştir " + this.$helper.tarihStr(this.tahsilat.tarih) + "\n\n" +
      "Alacaklı Vekili \n" +
      (this.yetkiliAvukat? this.yetkiliAvukat?.first_name : '' ) + " " + (this.yetkiliAvukat? this.yetkiliAvukat?.last_name : '' ) + " \t(Tahsilatı Yapan: " +
      (this.tahsilat.tahsilati_yapan??"") + ")";
}
